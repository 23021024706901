import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function updateDetTypeBonusByTypeShift(iddetBonusType, idtypeshift) {
  const response = await apiService.post('/bonificacion/detTipoBonificacion/tipoTurno', {
    iddetTipoBonificacion: iddetBonusType,
    idtipoTurno: idtypeshift,
  });

  return response;
}
