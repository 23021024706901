import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getPayrollPerUnitAndPosition(unitId, positionId, includeSectors) {
  const response = await apiService.get(`/planilla/${unitId}/${positionId}`, {
    params: {
      incluir_sectores: includeSectors,
    },
  });
  return response;
}
