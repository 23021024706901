import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Mixpanel
import mixpanel from 'mixpanel-browser';
// hooks
import useAuth from '@rflex-spa/react-core/dist/base/hooks/useAuth';
// pages
import Login from '@rflex-spa/react-core/dist/base/pages/auth/Login';
import InstanceSelect from '@rflex-spa/react-core/dist/base/pages/auth/InstanceSelect';
import { getSession, isSessionExpiredByDayAfter, isValidSession } from '@rflex-spa/react-core/dist/base/utils/jwt';
import { datetimeFormatString, formatDateTime } from '@rflex-spa/react-core/dist/base/utils/formatTime';
import MultifactorAuthentication from '@rflex-spa/react-core/dist/base/sections/auth/multifactor-authentication';
// components
import LoadingScreen from '@rflex-spa/react-core/dist/base/components/LoadingScreen';
// config
import appConfig from '../config/app';
// utils
import GoogleTagManager from '../utils/gtm';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user, instanceSelected, nominus, logout } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const currentSession = getSession();  

    if (!isValidSession(currentSession)) {
      return;
    }

    const handleLogout = async () => {
      await logout();
    }

    const currentTime = datetimeFormatString(formatDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss'));
    const createdAtToken = new Date(currentSession.createdAtToken);

    if (isSessionExpiredByDayAfter(currentTime, createdAtToken, true)) {
      handleLogout();
      console.log('Redirigiendo al login...');
      setRequestedLocation('/');
    }
  }, [pathname, logout])

  mixpanel.init(appConfig.mixpanelToken, { debug: appConfig.mixpanelDebug });

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || (nominus && nominus.dt)) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (nominus && nominus.twofa && !nominus.twofaValidated) {
    return <MultifactorAuthentication />;
  }

  if (instanceSelected == null) {
    return <InstanceSelect />;
  }

  mixpanel.identify(String(user.id));
  mixpanel.people.set({
    $email: user.email,
    $name: user.displayName,
    $avatar: user.avatarUrl,
    'User ID': user.id,
    'Client Name': instanceSelected.name,
  });

  GoogleTagManager.setGlobalContext();
  GoogleTagManager.setUserContext(user, instanceSelected);

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
