import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * @param   {string} selectedDate - Date format 'yyyy-MM-dd'
 * @param   {number} isDayShift - Enum values: (1|0)
 * @param   {Object} data
 * @param   {number[]} data.listaIdUnidad
 * @param   {number[]} data.listaIdDetPuesto
 * @returns {Promise<Object[]>}
 */
export default async function getShifts(selectedDate, isDayShift, data) {
  const response = await apiService.post(
    `/turnos/fecha/${selectedDate}/turno-dia/${isDayShift}/unidades-puestos`,
    data
  );

  return response;
}
