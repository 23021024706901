import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function downloadExcelLeave(info) {
  const response = await apiService.get(`/permiso/reporte/descarga`, {
    params: info,
    responseType: 'blob',
  });

  return response;
}
