// utils
import getSvgIcon from '@rflex-spa/react-core/dist/base/utils/getSvgIcon';
// routes
import { APP_DT_REPORTS_ROUTES, APP_DT_ROUTES } from './paths';

// ----------------------------------------------------------------------

const ICONS = {
  check: getSvgIcon('ic_cierreperiodo'),
  repocerti: getSvgIcon('ic_repocerti'),
};

const navConfig = [
  {
    items: [
      {
        title: 'Reportes DT',
        path: APP_DT_REPORTS_ROUTES.root,
        icon: ICONS.repocerti,
      },
      {
        title: 'Validación de Hash',
        path: APP_DT_ROUTES.hashValidation,
        icon: ICONS.check,
      },
    ],
  },
];

export default navConfig;
