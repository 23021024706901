import axios from 'axios';
// config
import { RFLEX_API_BASE_URL } from '@rflex-spa/react-core/dist/base/config';
// utils
import { getSession, isValidSession } from '@rflex-spa/react-core/dist/base/utils/jwt';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: RFLEX_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const currentSession = getSession();

    if (isValidSession(currentSession)) {
      if (currentSession.nominus) {
        config.headers.Authorization = `Bearer ${currentSession.nominus.jwt.token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosInstance;
