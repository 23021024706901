import apiService from '../../utils/getApi';
// ----------------------------------------------------------------------

export default async function getEnrollmentsTimeClocks(data) {
  const response = await apiService.get(`/reloj/trabajador/estado-enrolamiento-en-reloj`, {
    params: data,
    responseType: 'blob',
  });
  return response;
}
