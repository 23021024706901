import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * @param   {object} dto
 * @param   {string} dto.fechaInicio [Date format: 'yyyy-MM-dd']
 * @param   {string} dto.fechaTermino [Date format: 'yyyy-MM-dd']
 * @param   {int[]} dto.listaIdUnidad
 * @param   {int[]} dto.listaIdDetPuesto
 * @returns {Promise<Blob>}
 */
export default async function generateHolidayTimeReport(dto) {
  const response = await apiService.post(
    'reporte/horas-festivas',
    dto,
    { responseType: 'blob' }
  );

  return response;
};
