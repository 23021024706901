// utils
import getSvgIcon from '@rflex-spa/react-core/dist/base/utils/getSvgIcon';
// routes
import { ROUTES } from './paths';

// ----------------------------------------------------------------------

const ICONS = {
  cardAccount: getSvgIcon('ic_card_account'),
};

const navConfig = [
  {
    items: [
      {
        code: 'teletrabajo',
        title: 'Teletrabajo',
        path: ROUTES.teletrabajo,
        icon: ICONS.cardAccount,
      },
    ],
  },
];

export default navConfig;
