import axios, { CanceledError } from 'axios';
import { extractDomainFromUrl } from './general';

export const createApiService = (code) => {
  const axiosInstance = axios.create({
    baseURL: createApiBaseUrl(code),
  });

  axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (error instanceof CanceledError) {
        return Promise.reject(error);
      }

      const defaultError = { message: 'Something went wrong' };
      const resError = error.response ? error.response.data : defaultError;

      return Promise.reject(resError);
    }
  );

  return axiosInstance;
};

const createApiBaseUrl = (code) => {
  const productUrlFragment = extractDomainFromUrl();

  if (productUrlFragment.endsWith('dev')) {
    return `https://${code.toLowerCase()}-api.${productUrlFragment}`;
  }

  return `https://api.${code.toLowerCase()}.${productUrlFragment}`;
};
