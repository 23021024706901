import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getExportPunches(startDate, endDate, unitPositionList) {
  const response = await apiService.get('/marcas/exportable/entre-fechas/', {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      listaUnidadPuesto: unitPositionList,
    },
    responseType: 'blob',
  });

  return response;
}
