import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getEmployees(queryParams, abortController = null) {
  const response = await apiService.get(`/trabajadores/responder`, {
    signal: abortController ? abortController.signal : undefined,
    params: queryParams,
  });

  return response.data;
}
