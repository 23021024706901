import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * @param   {object} params
 * @param   {int[]}  params.listaIdUnidad
 * @param   {int[]}  params.listaIdDetPuesto
 * @param   {int[]}  params.listaIdTrabajador
 * @param   {int[]}  params.listaIdTipoContrato
 * @param   {bool}   params.requierePartTime
 * @param   {bool}   params.soloPartTime
 * @returns {Blob}
 */
export default async function generateHiredWorkersReport(params) {
  const response = await apiService.post(
    'reporte/trabajadores-contratados',
    params,
    { responseType: 'blob' }
  );

  return response;
}
