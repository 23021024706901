import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getWorkerShifts(workerId, queryParams) {
  const response = await apiService.get(`/turno/trabajador/${workerId}`, {
    params: queryParams,
  });

  return response;
}
