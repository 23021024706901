import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getSeeFdaByNameFile(nameFile, info) {
  const response = await apiService.get(`/fda/trabajador/ver/reporte-secure/${nameFile}`, {
    params: {
      info,
    },
    responseType: 'blob',
  });

  return response;
}
