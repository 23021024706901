import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function exportAbsence(starDate, endDate, listUnits) {
  const response = await apiService.post('/exportar/inasistencias', {
    fechaInicio: starDate,
    fechaTermino: endDate,
    listaUnidad: listUnits
  });

  return response;
}


