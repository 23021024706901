import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function InvalidatePreSolicitud(id, value) {
  const response = await apiService.post(`/hedav/preSolicitud/actualizar-rechazo/${id}`, {
    params: {
      rechazada: value,
    },
  });

  return response;
}
