import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getWorkerPermissionReportExcel(workerId, startDate, endDate, permissionIdlist) {
  const response = await apiService.get(`permisos/trabajador/${workerId}/descargar/excel`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      listaIdDetPermiso: permissionIdlist,
    },
  });

  return response;
}
