import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getLeave(data) {
  const response = await apiService.get(`/permisos`, {
    params: data,
  });

  return response.data;
}
