import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getShiftByUnitAndPosition(data) {
  const response = await apiService.get(`/turnos/unidades-puestos`, {
    params: data,
  });

  return response;
}
