import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Obtiene la lista de tipos de turno para un trabajador específico si se proporciona un ID.
 * Si no se pasa un ID de trabajador, obtiene la lista completa de tipos de turno.
 *
 * @param {number|null} employeeId - El ID del trabajador para filtrar los tipos de turno (opcional).
 * @returns {Promise} - La respuesta de la API con la lista de tipos de turno.
 */
export default async function getTypeShiftByEmployee(employeeId = null) {
  const response = await apiService.get(`/tipos-de-turno`, {
    params: {
      id_trabajador: employeeId,
    },
  });
  return response;
}
