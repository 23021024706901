import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getSectorWithAdmins = async (id) => {
  const response = await apiService.get(`sector/traerSectoresConAdmins/${id}`);

  return response;
};

export default getSectorWithAdmins;
