import { createApiService } from "../../utils/createApi";

const pdfResultValidation = async (data, instanceCode) => {
  const apiService = createApiService(instanceCode);

  const response = await apiService.post(`/validation/response/`, data);

  return response;
};

export default pdfResultValidation;
