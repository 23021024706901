import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getReportPersonalShiftBySector2(objConsulta) {
  const response = await apiService.get('/reporte/personalTurnoSector_2', {
    params: {
      fecha: objConsulta.fecha,
      idunidad: objConsulta.idunidad,
      idpuesto: objConsulta.idpuesto,
    },
  });
  return response;
}
