import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getBalanceHoursByWorker(startDate, endDate, employeeId) {
  const response = await apiService.get(`/saldoHora/reporte/trabajador`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      idtrabajador: employeeId,
    },
  });
  return response;
}
