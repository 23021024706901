import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Generate a report of the delays by day
 * @param   {Object} dto
 * @param   {string} dto.fechaInicio - Date format 'YYYY-MM-DD'
 * @param   {string} dto.fechaTermino - Date format 'YYYY-MM-DD'
 * @param   {int[]} dto.listaIdUnidad
 * @param   {int[]} dto.listaIdDetPuesto
 * @param   {int[]} dto.listaIdTipoContrato
 * @param   {boolean} dto.incluirPartTime
 * @param   {boolean} dto.conMarca
 * @returns {Promise<Blob>}
 */
export default async function generateDelaysByDayReport(dto) {
  return apiService.post(
    `/reporte/atrasos-por-dia`,
    dto,
    { responseType: 'blob' }
  );
}
