import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getShiftByEmployeeId(employeeId, startDate, endDate) {
  const response = await apiService.get(`/turnoporidtrabajador/${startDate}/${endDate}`, {
    params: {
      idtrabajador: [employeeId],
    },
  });
  return response;
}
