import apiService from '../../utils/getApi';

const linkCC = async (costCenterId, unitId) => {
  const response = await apiService.post('/auditoria/cc/enlazar', {
    idCentroCosto: costCenterId,
    idunidad: unitId,
  });

  return response;
};

export default linkCC;
