import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getAdministratorByUsername(username) {
  const response = await apiService.get('/admin/by-username/', {
    params: {
      username,
    },
  });

  return response;
}
