import ReactDOM from 'react-dom';
// Mixpanel
import mixpanel from 'mixpanel-browser';
import App from '@rflex-spa/react-core';
// redux
import { getReducers } from './redux';
// routes
import { getRoutes } from './routes';
// config
import appConfig from './config/app';
import authCallbackConfig from './config/auth';

// ----------------------------------------------------------------------

mixpanel.init(appConfig.mixpanelToken, { debug: appConfig.mixpanelDebug });

ReactDOM.render(
  <App initConfig={appConfig} authCallback={authCallbackConfig} routes={getRoutes()} reducers={getReducers()} notPersistSession/>,
  document.getElementById('root')
);
