import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function updateBonificationShiftDetail(bonificationTypeDetailId, shiftDetailId) {
  const response = await apiService.post('bonificacion/detTipoBonificacion/detTurno', {
    iddetTipoBonificacion: bonificationTypeDetailId,
    iddetTurno: shiftDetailId,
  });
  return response;
}
