import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getBalanceHoursByUnit(startDate, endDate, unitId, estateIds) {
  const response = await apiService.get(`/saldoHora/reporte/unidad-estamento`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      idunidad: unitId,
      iddetPuesto: estateIds,
    },
  });
  return response;
}
