class SessionExpiredError extends Error {
  constructor(message) {
	super(message);

	this.name = 'SessionExpiredError';
    this.code = 'ERR_SESSION_EXPIRED';
	this.message = message || 'Session has expired';

	if (Error.captureStackTrace) {
	  Error.captureStackTrace(this, SessionExpiredError);
	}
  }
}

export default SessionExpiredError;
