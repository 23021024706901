import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getRotaryType = async (queryParams) => {
  const response = await apiService.get('/tipoRotativa', { params: queryParams });

  return response;
};

export default getRotaryType;
