import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getDetPuesto = async () => {
  const response = await apiService.get('detPuesto');

  return response;
};

export default getDetPuesto;
