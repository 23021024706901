import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getCorrectedMarksList(fechaInicio, fechaTermino, queryParams) {
  const response = await apiService.get(`/correccionMarcasCorregidas/${fechaInicio}/${fechaTermino}`, {
    params: queryParams,
  });
  return response;
}
