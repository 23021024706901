import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getWorkersFda(queryParams) {
  const response = await apiService.get('/fda/trabajadores', {
    params: queryParams,
  });
  return response;
}
