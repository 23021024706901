import apiService from '../../utils/getApi';

const getValidationDocument = async (data) => {
  const response = await apiService.post(`/validation/document/download`, data, {
    responseType: 'blob',
  });

  return response;
};

export default getValidationDocument;
