import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getActiveManning = async (queryParams) => {
  const response = await apiService.get(`/dotacion-actividad`, { params: queryParams });

  return response;
};

export default getActiveManning;
