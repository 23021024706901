import axios from 'axios';

// ----------------------------------------------------------------------

/**
 * Envia la respuesta de la encuesta NPS
 * @param {number} surveyId 
 * @param {number} score 
 * @param {string} comment 
 * @param {string} npsType 
 * @returns
 */
export default async function npsInsertAnswer(surveyId, score, comment, npsType = 'gdp') {
    const formdata = new FormData();
    formdata.append("survey_id", surveyId);
    formdata.append("nps_type", npsType);
    formdata.append("score", score);
    formdata.append("comment", comment);

    const response = await axios.post(
        "https://us-central1-operations-379520.cloudfunctions.net/insert_nps_answer",
        formdata,
        {headers: {"Content-Type": "multipart/form-data",},}
    );
    return response.data;
}