import { createSlice } from '@reduxjs/toolkit';
// api
import { getUserPermissions } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userPermissions: null,
};

const slice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.userPermissions = {
        rol: {
          code: null,
          name: null,
        },
        routes: [],
      };
      state.isLoading = false;
      state.error = action.payload;
    },

    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    reset: () => initialState,
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUserPermissions } = slice.actions;

// ----------------------------------------------------------------------

export function requestUserPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userPermissions = await getUserPermissions();
      dispatch(slice.actions.setUserPermissions(userPermissions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetUserPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.reset());
  };
}
