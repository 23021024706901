import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function  saveValidatedShiftsImporter(shifts) {
  const response = await apiService.post('/massive-shift-loading/save-validated-shifts', {
    shifts,
  });
  
  return response;
}