import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getTemplateImporterShifts(startDate, endDate, unitIdList, detPuestoIdList, contractTypeIdList) {
  const response = await apiService.post(
    '/massive-shift-loading/generate-template',
    {
        startDate,
        endDate,
        unitIdList,
        detPuestoIdList,
        contractTypeIdList
    },
    { responseType: 'blob' }
  );

  return response;
}
