import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function exportShiftWithoutPunch(starDate, endDate, listUnits, listPositions) {
  const response = await apiService.post('/turnos/unidades-puestos/sin-marcas/exportar/excel', {
    fechaInicio: starDate,
    fechaTermino: endDate,
    listaIdUnidad: listUnits,
    listaIdDetPuesto: listPositions
  });

  return response;
}


