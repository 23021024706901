import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function downloadAttendanceByEmployeeId(employeeId, startDate, endDate) {
  const response = await apiService.post('/marcas-por-trabajador/excel', {
    idtrabajador: employeeId,
    fecha_inicio: startDate,
    fecha_termino: endDate
  });

  return response;
}
