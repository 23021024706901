import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of shift with pending marks
 * @param {string} startDate
 * @param {string} endDate
 * @param {array|null} unitsIdList
 * @param {array|null} workerIdList
 * @returns
 */
export default async function getShiftWithPendingMarksReport(startDate, endDate, unitsIdList, workerIdList) {
  const params = {
    start_date: startDate,
    end_date: endDate,
  };
  if (unitsIdList) {
    params.units_id_list = unitsIdList;
  }
  if (workerIdList) {
    params.worker_id_list = workerIdList;
  }

  const response = await apiService.post(`/turno/con-marcas-pendientes/descargar/excel`, params, {
    responseType: 'blob',
  });
  return response;
}
