import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getAbsence(data) {
  const response = await apiService.get(`/inasistencias`, {
    params: data,
  });

  return response;
}
