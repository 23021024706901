import apiService from '../../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getFoodlawPdf(id) {
  const response = await apiService.get(`/pa/descargar-pdf/${id}`, {
    responseType: 'blob',
  });

  return response;
}
