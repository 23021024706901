import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function updateAdministrator(administrator) {
  const response = await apiService.put('/admins/update', {
    admin: administrator,
  });

  return response;
}
