import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of called shifts by units
 * @param {string} startDate
 * @param {string} endDate
 * @param {array} unitsIdList
 * @param {boolean} detailedReport
 * @returns
 */
export default async function getExportReportCalled(startDate, endDate, unitsIdList, detailedReport) {
  const response = await apiService.post(
    `/llamados/presolicitudes/descargar/excel`,
    {
      start_date: startDate,
      end_date: endDate,
      units_id_list: unitsIdList,
      detailed_report: detailedReport,
    },
    {
      responseType: 'blob',
    }
  );
  return response;
}
