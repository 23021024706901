import PropTypes from 'prop-types';

// Freshchat component
import Freshchat from '../Freshchat';

// ------------------------------------------------------

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default function PageWrapper({ children }) {
  return (
    <>
      <Freshchat />
      {children}
    </>
  );
}
