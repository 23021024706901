import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getEnrolledUserByUserCode(userCode) {
  const response = await apiService.get(`/presenza/enrolled-user-by-id`, {
    params: {
      user_code: userCode,
    },
  });

  return response;
}
