import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Get the data of the 'delays by day' report
 * @param   {Object} dto
 * @param   {string} dto.fechaInicio - Date format 'YYYY-MM-DD'
 * @param   {string} dto.fechaTermino - Date format 'YYYY-MM-DD'
 * @param   {int[]} dto.listaIdUnidad
 * @param   {int[]} dto.listaIdDetPuesto
 * @param   {int[]} dto.listaIdTipoContrato
 * @param   {boolean} dto.incluirPartTime
 * @param   {boolean} dto.conMarca
 * @returns {Promise<Object[]>}
 */
export default async function getDelaysByDayData(dto) {
  return apiService.get(`/reporte/atrasos-por-dia`, {
    params: dto,
  });
}
