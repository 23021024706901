import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getReportCalled(startDate, endDate, unitId) {
  const response = await apiService.get(`/llamados/presolicitudes/`, {
    params: {
      fecha_inicio: startDate,
      fecha_termino: endDate,
      idunidad: unitId,
    },
  });
  return response;
}
