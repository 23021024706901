import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// Core components
import { isEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';
import useAuth from '@rflex-spa/react-core/dist/base/hooks/useAuth';
import LoadingScreen from '@rflex-spa/react-core/dist/base/components/LoadingScreen';
import { useDispatch } from '@rflex-spa/react-core/dist/redux/store';
import InvalidPermissionView from '@rflex-spa/react-core/dist/base/pages/errors/InvalidPermissionView';
// redux
import { requestAppConfig } from '../../redux/slices/appConfig';
import { requestUnitUnlinked, requestUnits } from '../../redux/slices/units';
import { requestInitSettings } from '../../redux/slices/initSettings';
import { requestUserPermissions } from '../../redux/slices/userPermissions';
import { requestDetImprovements } from '../../redux/slices/detImprovements';
import { loadAssociatedAdminSpreadsheets } from '../../redux/slices/spreadsheetConfig';
// Hooks
import usePermissions from '../../hooks/usePermissions';

// ------------------------------------------------------

InitialConfigsWrapper.propTypes = {
  children: PropTypes.node,
};

export default function InitialConfigsWrapper({ children }) {
  // Constants
  const dispatch = useDispatch();

  // State (Global)
  const { isPermissionsLoading, userPermissions, checkConfig } = usePermissions();
  const { instanceSelected, isAuthenticated, nominus } = useAuth();

  // TODO: Refactorizar para llevar el estado de la sesión a un custom hook
  const isLoggedIn = useMemo(() => isAuthenticated || (nominus && nominus.dt), [isAuthenticated, nominus]);

  // Hooks
  useEffect(() => {
    if (instanceSelected != null) {
      dispatch(requestInitSettings());
      dispatch(requestUserPermissions());
      dispatch(requestUnits());
      dispatch(requestAppConfig(instanceSelected.code));
      dispatch(requestDetImprovements());
      if (instanceSelected.userData.role === 'admin') {
        dispatch(requestUnitUnlinked());
        dispatch(loadAssociatedAdminSpreadsheets());
      }
    }
    // eslint-disable-next-line
  }, [instanceSelected, dispatch]);

  const _renderChildren = useCallback(() => {
    // Variable que representa el estado de carga de las configuraciones esenciales del sistema
    if (isPermissionsLoading) {
      return <LoadingScreen />;
    }

    // Si no tiene el mantenedor de perfiles activo o si no está logueado, no necesita revisar permisos
    if (!checkConfig('aplicacionGeneral.habilitarAcl') || !isLoggedIn) {
      return children;
    }

    // Si no tiene roles o permisos asignados devolver pantalla de error de permisos
    if (isEmpty(userPermissions?.rol) || isEmpty(userPermissions?.routes)) {
      return <InvalidPermissionView />;
    }

    return children;
  }, [isPermissionsLoading, userPermissions, isLoggedIn, children, checkConfig]);

  return <>{_renderChildren()}</>;
}
