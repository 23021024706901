import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getEmployeesWithImprovements(data) {
  const response = await apiService.get(`/trabajadores/traerTrabajadoresConBonificacion`, {
    params: data,
  });

  return response;
}
