const FRESHCHAT_HOST = process.env?.REACT_APP_FRESHCHAT_HOST || '';
const FRESHCHAT_TOKEN = process.env?.REACT_APP_FRESHCHAT_TOKEN || '';
const FRESHCHAT_WIDGET_UUID = process.env?.REACT_APP_FRESHCHAT_WIDGET_UUID || '';

const fcSettings = {
  host: FRESHCHAT_HOST,
  token: FRESHCHAT_TOKEN,
  widgetUuid: FRESHCHAT_WIDGET_UUID,
  locale: 'es',
  config: {
    content: {
      placeholders: {
        search_field: 'Buscar',
        reply_field: 'Responder',
        csat_reply: 'Comenta aquí',
      },
      actions: {
        csat_yes: 'Sí',
        csat_no: 'No',
        push_notify_yes: 'Sí',
        push_notify_no: 'No',
        tab_faq: 'Soluciones',
        tab_chat: 'Chat',
        csat_submit: 'Enviar',
      },
      headers: {
        chat: 'Escríbenos',
        chat_help: 'Escríbenos si tienes cualquier duda',
        faq: 'Artículos de ayuda',
        faq_help: 'Busca nuestros artículos',
        faq_not_available: 'No se encontraron artículos',
        faq_search_not_available: 'No se encontraron artículos para {{query}}',
        faq_useful: 'Te fue útil este artículo?',
        faq_thankyou: 'Gracias por tu feedback',
        faq_message_us: 'Escríbenos',
        push_notification: 'No te pierdas nuestras respuestas! Habilitar notificaciones?',
        csat_question: 'Resolvimos tus dudas?',
        csat_yes_question: 'Cómo calificarías esta interacción?',
        csat_no_question: 'Cómo podríamos mejorar?',
        csat_thankyou: 'Gracias por tu respuesta',
        csat_rate_here: 'Evalúa nuestra ayuda',
        channel_response: {
          offline: 'No estamos en línea ahora. Por favor, déjanos un mensaje',
          online: {
            minutes: {
              one: "Actualmente respondiendo en {!time!} minutos",
              more: "Típicamente responde en {!time!} minutos",
            },
            hours: {
              one: "Actualmente respondiendo en menos de una hora",
              more: "Típicamente responde en {!time!} horas",
            },
          },
        },
      },
    },
  },
};

export default fcSettings;
