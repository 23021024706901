import { createSlice } from '@reduxjs/toolkit';
import { report } from '@rflex-spa/react-core/dist/sentry';
import { getAssociatedAdminSpreadsheets } from '../../api';
// ----------------------------------------------------------------------

const initialState = {
  spreadsheetConfig: {
    listaSector: undefined,
    listaTipoTurno: undefined,
    listaDetPuesto: undefined,
    listaTipoRotativa: undefined,
    puestoActual: undefined,
    datosPlanilla: undefined,
  },
  adminSpreadsheets: [],
  isLoadingAdminSpreadsheets: false,
};

const slice = createSlice({
  name: 'spreadsheetConfig',
  initialState,
  reducers: {
    setSpreadsheetConfig(state, action) {
      state.spreadsheetConfig = {
        ...state.spreadsheetConfig,
        [action.payload.name]: action.payload.value,
      };
    },

    setAdminSpreadsheets(state, action) {
      state.adminSpreadsheets = action.payload;
    },

    setIsLoadingAdminSpreadsheets(state, action) {
      state.isLoadingAdminSpreadsheets = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSpreadsheetConfig, setAdminSpreadsheets, setIsLoadingAdminSpreadsheets } = slice.actions;

// ----------------------------------------------------------------------

// Methods
export function setConfig(name, value) {
  return (dispatch) => {
    dispatch(slice.actions.setSpreadsheetConfig({ name, value }));
  };
}

export const loadAssociatedAdminSpreadsheets = () => async (dispatch) => {
  dispatch(slice.actions.setIsLoadingAdminSpreadsheets(true));
  try {
    const adminsAndSpreadsheets = await getAssociatedAdminSpreadsheets();

    const planillas = adminsAndSpreadsheets.reduce((acc, admin) => {
      const planillasIdsExistentes = acc.map((planilla) => planilla.idplanilla);
      const planillasFiltradas = admin.planillas
        .filter((planilla) => !planillasIdsExistentes.includes(planilla.idplanilla))
        .map((planilla) => ({
          ...planilla,
          idadmin: admin.idadmin,
          usuario: admin.usuario,
        }));

      return [...acc, ...planillasFiltradas];
    }, []);

    dispatch(slice.actions.setAdminSpreadsheets(planillas));
  } catch (error) {
    report(error);
  } finally {
    dispatch(slice.actions.setIsLoadingAdminSpreadsheets(false));
  }
};