import { createSlice } from '@reduxjs/toolkit';
// api
import { getTypeConfiguration } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  initSettings: [],
};

const slice = createSlice({
  name: 'initSettings',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setInitSettings(state, action) {
      state.initSettings = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setInitSettings } = slice.actions;

// ----------------------------------------------------------------------

export function requestInitSettings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await getTypeConfiguration();
      dispatch(slice.actions.setInitSettings(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
