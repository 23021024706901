import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Generate a report of workers shifts grouped by sector for every position
 * @param   {object} dto
 * @param   {string} dto.fecha [Date format: 'yyyy-MM-dd']
 * @param   {int} dto.turnoDia [(1|0)]
 * @param   {int[]} dto.listaIdUnidad
 * @param   {int[]} dto.listaIdDetPuesto
 * @returns {Promise<Blob>}
 */
export default async function generateWorkersShiftsGroupedBySectors(dto) {
  return apiService.post(
    '/reporte/personal-por-sector',
    dto,
    { responseType: 'blob' }
  );
}
