import apiService from '../../utils/getNominusApi';

// ----------------------------------------------------------------------

export default async function userNominusProcess(name, email, password) {
  const user = {
    name,
    email,
  };

  if (password) {
    user.password = password;
  }

  const response = await apiService.post('/api/users/process', {
    user,
  });

  return response.data;
}
