import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getDeliveredTickets(mealClocksList, startDate, endDate) {
  const response = await apiService.get(`/reloj/tickets/emitidos`, {
    params: {
      listaRelojesCasino: mealClocksList,
      fechaInicio: startDate,
      fechaTermino: endDate,
    },
    responseType: 'blob',
  });
  return response;
}
