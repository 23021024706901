import packageJson from '../../package.json';

const reset = () => {
  window.dataLayer.push({
    userData: null,
    dt: null,
  });
};

const setGlobalContext = () => {
  const portal = process.env.REACT_APP_THEME || 'rflex';

  window.dataLayer.push({
    globalData: {
      environment: process.env.REACT_APP_ENVIRONMENT,
      version: packageJson.version,
      portal,
    },
  });
};

const setUserContext = (user, instanceSelected) => {
  window.dataLayer.push({
    userData: {
      userId: instanceSelected.userData.id,
      userType: instanceSelected.userData.role,
      userEmail: user.email,
      clientCode: instanceSelected.code,
      clientName: instanceSelected.name,
    },
  });
};

const setDTContext = (nominus, user) => {
  window.dataLayer.push({
    dt: {
      urlClientSelected: nominus.dt.clientSelected.url,
      codeClienteSelected: nominus.dt.clientSelected.code,
      nameClienteSelected: nominus.dt.clientSelected.name,
      userId: user.id,
      userEmail: user.email,
      userDisplayName: user.displayName,
      ip: nominus.dt.ip,
    },
  });
};

export default {
  reset,
  setGlobalContext,
  setUserContext,
  setDTContext,
};
