import { createSlice } from '@reduxjs/toolkit';
import { getValidationProcessesData, getWorkersData } from '../../../../../api';

const initialState = {
  workersData: [],
  processesData: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'documentValidationProcess',
  initialState,
  reducers: {
    setWorkersData(state, action) {
      state.workersData = action.payload;
    },
    setProcessesData(state, action) {
      state.processesData = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    setIsLoadingProcessesData(state, action) {
      state.isLoading = action.payload;
      checkOverallLoadingState(state);
    },
    setIsLoadingWorkersData(state, action) {
      state.isLoading = action.payload;
      checkOverallLoadingState(state);
    },
    // Loading de uso general
    setIsLoading(state, action) {
      state.isLoading = action.payload;
      checkOverallLoadingState(state);
    },
  },
});

// Actions
export const { setWorkersData, setIsLoading, setProcessesData, hasError } = slice.actions;

// Methods

// Si alguno de los loading está cargando entonces se setea en true
function checkOverallLoadingState(state) {
  state.isLoading = state.isLoadingProcesses || state.isLoadingWorkers || state.isLoading;
}

export function loadValidationProcessesData() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const data = await getValidationProcessesData();

      dispatch(slice.actions.setProcessesData(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}

export function loadWorkersData() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const response = await getWorkersData();
      const parsedWorkers = response.map((worker) => {
        const nombreCompleto = `${worker.nombre} ${worker.apellidoPaterno} ${worker.apellidoMaterno}`;
        return {
          ...worker,
          nombreCompleto,
        };
      });

      dispatch(slice.actions.setWorkersData(parsedWorkers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}

// Reducer
export default slice.reducer;
