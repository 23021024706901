import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getCausesDismissalsReportExcel(startDate, endDate, unitIdList, positionIdlist) {
  const response = await apiService.post('/inasistencias/reporte/causal-despido/descargar/excel', {
    fechaInicio: startDate,
    fechaTermino: endDate,
    listaIdUnidad: unitIdList,
    listaIdDetPuesto: positionIdlist,
  });

  return response;
}
