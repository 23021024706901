// Mixpanel
import mixpanel from 'mixpanel-browser';
// redux
import { resetUserPermissions } from '../redux/slices/userPermissions';
// web services (apis rest)
import apiService from '../utils/getApi';
import GoogleTagManager from '../utils/gtm';

// ----------------------------------------------------------------------

const authCallbackConfig = {
  onGuest: () => {
    GoogleTagManager.setGlobalContext();
  },

  onInitialize: (token) => {
    apiService.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  onLogin: (token) => {
    apiService.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  onLogout: (states, dispatch) => {
    dispatch(resetUserPermissions());

    mixpanel.reset();
    GoogleTagManager.reset();

    delete apiService.defaults.headers.common.Authorization;
  },
};

export default authCallbackConfig;
