import apiService from '../../utils/getApi';
// ----------------------------------------------------------------------

export default async function getPunchesWrongTimeClock(startDate, endDate) {
  const response = await apiService.get(`/reloj/marca/rechazos`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
    },
    responseType: 'blob',
  });
  return response;
}
