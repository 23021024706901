import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of shift with corrected corrections
 * @param {string} startDate
 * @param {string} endDate
 * @param {array|null} unitsIdList
 * @param {array|null} detPuestoList
 * @param {array|null} workerIdList
 * @returns
 */
export default async function getResolvedMarksReport(startDate, endDate, unitsIdList, detPuestoList, workerIdList) {
  const params = {
    start_date: startDate,
    end_date: endDate,
  };

  if (unitsIdList) {
    params.units_id_list = unitsIdList;
  }
  if (detPuestoList) {
    params.detpuesto_id_list = detPuestoList;
  }
  if (workerIdList) {
    params.worker_id_list = workerIdList;
  }
  const response = await apiService.post(`/solicitud/corrector-marca/corregidas/descargar/excel`, params, {
    responseType: 'blob',
  });
  return response;
}
