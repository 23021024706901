import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getShiftPartTime(data) {
  const response = await apiService.get(`/reporte/turnosPartTime`, {
    params: data,
  });
  return response;
}
