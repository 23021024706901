import { createApiService } from "../../utils/createApi";

const revertMarkCorrection = async (encryptedInfo, instanceCode) => {
  const apiService = createApiService(instanceCode);

  const response = await apiService.put(`/corrector-marca/revertir/${encryptedInfo}`);

  return response;
};

export default revertMarkCorrection;
