import { createApiService } from "../../utils/createApi";

const getValidationProcessData = async (token, instanceCode) => {
  const apiService = createApiService(instanceCode);

  const response = await apiService.get(`/validation/process-data/${token}`);

  return response;
};

export default getValidationProcessData;
