import axios, { CanceledError } from 'axios';
// utils
import { getSession, isValidSession } from '@rflex-spa/react-core/dist/base/utils/jwt';
import { AUTH_ROUTES } from '@rflex-spa/react-core/dist/routes/paths';
// errors
import SessionExpiredError from '../api/errors/SessionExpiredError';
import UnknownInstanceError from '../api/errors/UnknownInstanceError';

// ----------------------------------------------------------------------
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const currentSession = getSession();

    if (!isValidSession(currentSession)) {
      throw new SessionExpiredError();
    }

    if (!currentSession.instanceSelected) {
      throw new UnknownInstanceError();
    }

    config.headers.Authorization = `Bearer ${currentSession.instanceSelected.jwt.token}`;
    config.baseURL = currentSession.instanceSelected.url;

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error instanceof CanceledError) {
      return Promise.reject(error);
    }

    if (error instanceof SessionExpiredError) {
      console.log(`${error.message}. (Redirecting to login page)`);
      window.location.href = AUTH_ROUTES.login;

      return Promise.reject(error);
    }

    if (error instanceof UnknownInstanceError) {
      console.log(`${error.message}. (Redirecting to index page)`);
      window.location.href = '/';

      return Promise.reject(error);
    }

    const defaultError = { message: 'Something went wrong' };
    const resError = error.response ? error.response.data : defaultError;

    return Promise.reject(resError);
  }
);

export const getApiBaseUrl = () => {
  const currentSession = getSession();

  if (!currentSession) {
    return null;
  }

  return currentSession.instanceSelected.url;
};

export default axiosInstance;
