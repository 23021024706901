import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getUnit = async (id, queryParams) => {
  const response = await apiService.get(`/unidades/${id}`, { params: queryParams });

  return response;
};

export default getUnit;
