import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getRedeemedMealTickets(redemptionPointsList, startDate, endDate) {
  const response = await apiService.get(`/reloj/tickets/cobrados`, {
    params: {
      listaPuntosCanje: redemptionPointsList,
      fechaInicio: startDate,
      fechaTermino: endDate,
    },
    responseType: 'blob',
  });
  return response;
}
