import rflexTheme from './rflexTheme';
import sateliteTheme from './sateliteTheme';

const theme = {
  rflex: {
    name: 'rflex',
    displayName: 'rFlex',
    ...rflexTheme,
  },
  satelite: {
    name: 'satelite',
    displayName: 'Satelite',
    ...sateliteTheme,
  },
};

export default theme;
