import { isNotEmpty, isEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';
import _ from 'lodash';

export function getConfig(listConfigs, configuracionIngresada, idunidad, iddetPuesto) {
  const objTypeConfig = listConfigs.find((item) => item.configuracion === configuracionIngresada);

  if (objTypeConfig === undefined || objTypeConfig === null) {
    console.warn(`No se encuentra registro de tipoConfiguracion para ${configuracionIngresada} Revisar BD.`);
    return undefined;
  }

  const checkList = checkListConfigurations(objTypeConfig.configuraciones, idunidad, iddetPuesto);

  return checkDateConfiguration(objTypeConfig, checkList[0]);
}

/**
 * Wrapper of getConfig function that returns true or false indicating if the configuration is enabled.
 * @param {array} configurationsList
 * @param {string} configuration
 * @returns boolean
 */
export function checkEnabledConfiguration(configurationsList, configuration) {
  const objConfiguration = getConfig(configurationsList, configuration);

  if (objConfiguration === undefined) {
    return false;
  }

  const habilitado =
    'habilitarPorDefecto' in objConfiguration ? objConfiguration.habilitarPorDefecto : objConfiguration.habilitado;

  if (typeof habilitado === 'boolean') {
    return habilitado;
  }

  if (typeof habilitado === 'number') {
    return habilitado === 1;
  }

  return false;
}

export function checkEnabledConfigurationByUnit(configurationsList, configuration) {
  const objConfiguration = configurationsList.find((item) => item.configuracion === configuration);
  return isNotEmpty(objConfiguration) && isNotEmpty(objConfiguration.configuraciones)
    ? checkConfigurationByUnit(objConfiguration.configuraciones)
    : undefined;
}

function checkConfigurationByUnit(configuraciones) {
  if (isEmpty(configuraciones)) {
    return undefined;
  }

  return _.filter(
    configuraciones,
    (objConfig) => !_.isNull(objConfig.unidad_idunidad) && objConfig.habilitado === true
  );
}

function checkDateConfiguration(objTipoConfiguracion, objConfiguracion) {
  switch (true) {
    case !objTipoConfiguracion.requiereFechaInicio:
      return objConfiguracion !== undefined ? objConfiguracion : objTipoConfiguracion;
    case objConfiguracion === undefined ||
      objConfiguracion === null ||
      objConfiguracion.det_tipo_configuracion === undefined ||
      objConfiguracion.det_tipo_configuracion === null ||
      objConfiguracion.det_tipo_configuracion.fechaInicio === undefined ||
      objConfiguracion.det_tipo_configuracion.fechaInicio === null:
      return undefined;
    default: {
      const fechaInicio = new Date(objConfiguracion.det_tipo_configuracion.fechaInicio);
      const fechaActual = new Date();
      return fechaInicio <= fechaActual ? objConfiguracion : undefined;
    }
  }
}

function checkListConfigurations(listaConfiguraciones, idunidad, iddetPuesto) {
  const listConfigurationByUnit = listaConfiguraciones.filter((item) => checkUnit(item, idunidad));

  return listConfigurationByUnit.filter((item) => checkDetPuesto(item, iddetPuesto));
}

function checkUnit(objConfiguracion, idunidad) {
  if (idunidad) {
    return objConfiguracion.unidad_idunidad === idunidad || objConfiguracion.unidad_idunidad === null;
  }

  return objConfiguracion.unidad_idunidad === null;
}

function checkDetPuesto(objConfiguracion, iddetPuesto) {
  if (iddetPuesto) {
    return objConfiguracion.detpuesto_iddetPuesto === iddetPuesto || objConfiguracion.detpuesto_iddetPuesto === null;
  }

  return objConfiguracion.detpuesto_iddetPuesto === null;
}

export const getConfigTxt = (appConfig, configType, configName) =>
  _.get(appConfig[configType], configName, _.get(appConfig.defaultConfig, configName));

export const getClinicalConfigTxt = (appConfig, configName) => getConfigTxt(appConfig, 'clinicalConfig', configName);

export const logEvaluationConfig = (nombre, caso) => {
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  let comentarioCaso;

  switch (caso) {
    case 1:
      comentarioCaso = ': sin config ni tipo configuracion';
      break;
    case 2:
      comentarioCaso =
        ': no tiene valor para la configuracion, se deberia usar el tipo de configuracion, pero esta funcion solo retorna el obj de configuracion no el tipo. Cambie de función.';
      break;
    case 3:
      comentarioCaso = ': no especificaste la unidad, por ende solo se buscaran los nulos para esta configuracion ';
      break;
    case 4:
      comentarioCaso = ': no especificaste el puesto, por ende solo se buscaran los nulos para esta configuracion. ';
      break;
    case 5:
      comentarioCaso =
        ': esta configuracion requiere de fecha inicio, y esta en la base de datos sin ninguna fecha , asi que se retorna indefinido. Chequee los detalles de esta configuracion. ';
      break;
    case 6:
      comentarioCaso = ': no existe el objeto detalle de configuracion, asi que no se puede retornar ningun valor. ';
      break;
    case 7:
      comentarioCaso = ': existe configuracion que coincide, pero la configuracion esta deshabilitada. ';
      break;
    default:
      comentarioCaso = 'caso no manejado.';
  }

  console.debug(`Config: ${nombre}${comentarioCaso}`);
};

export const determineShowOption = (configurationsList, enteredConfiguration, idunidad, iddetPuesto) => {
  const objConfig = getConfig(configurationsList, enteredConfiguration, idunidad, iddetPuesto);

  if (typeof objConfig === 'undefined') {
    logEvaluationConfig(enteredConfiguration, 1);
    return undefined;
  }

  return _.has(objConfig, 'habilitarPorDefecto') ? objConfig.habilitarPorDefecto > 0 : objConfig.habilitado > 0;
};

const getObjConfiguration = (listConfigs, configuracionIngresada, idunidad, iddetPuesto) => {
  const objConfig = getConfig(listConfigs, configuracionIngresada, idunidad, iddetPuesto);
  if (typeof objConfig === 'undefined') {
    logEvaluationConfig(configuracionIngresada, 1);
    return undefined;
  }

  if (_.has(objConfig, 'habilitarPorDefecto')) {
    logEvaluationConfig(configuracionIngresada, 2);
    return undefined;
  }

  if (!objConfig.habilitado) {
    logEvaluationConfig(configuracionIngresada, 7);
    return undefined;
  }

  return objConfig;
};

const getObjDetConfiguration = (listConfigs, configuracionIngresada, idunidad, iddetPuesto) => {
  const objConfig = getObjConfiguration(listConfigs, configuracionIngresada, idunidad, iddetPuesto);
  return typeof objConfig !== 'undefined' ? objConfig.det_tipo_configuracion : undefined;
};

const checkDetTypeConfiguration = (objDetConfiguracion, configuracionIngresada) => {
  if (typeof objDetConfiguracion === 'undefined') {
    logEvaluationConfig(configuracionIngresada, 5);
    return undefined;
  }

  const valor = _.get(objDetConfiguracion, 'valor', '');
  const tipoDatoValor = _.get(objDetConfiguracion, 'tipoDatoValor', '');

  switch (tipoDatoValor) {
    case '':
      return '';
    case 'string':
      return valor;
    case 'integer':
      return _.isNaN(parseInt(valor, 10)) ? 0 : parseInt(valor, 10);
    case 'json':
      return JSON.parse(valor);
    default:
      return '';
  }
};

export const getSpecificConfigurationValue = (listConfigs, configuracionIngresada, idunidad, iddetPuesto) => {
  const objDetConfig = getObjDetConfiguration(listConfigs, configuracionIngresada, idunidad, iddetPuesto);
  return typeof objDetConfig !== 'undefined'
    ? checkDetTypeConfiguration(objDetConfig, configuracionIngresada)
    : undefined;
};
