import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of the balance hours by unit
 * @param {string} startDate
 * @param {string} endDate
 * @param {array|null} unitId
 * @param {array|null} estateIds
 * @returns blob
 */
export default async function getBalanceHoursByUnitReport(startDate, endDate, unitId, puestoIds) {
  const response = await apiService.post(
    `/saldoHora/unidad-puesto/descargar/excel`,
    {
      start_date: startDate,
      end_date: endDate,
      units_id_list: unitId,
      detpuesto_id_list: puestoIds,
    },
    {
      responseType: 'blob',
    }
  );
  return response;
}
