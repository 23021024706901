import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getSectorsByUnit(data) {
  const response = await apiService.get(`/sectores`, {
    params: data,
  });
  return response;
}
