import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getWithoutShift(
  startDate,
  endDate,
  listUnitIds,
  listPositionIds,
  listSectorIds,
  listWorkerIds
) {
  const response = await apiService.get('/marcas-sin-turno', {
    params: {
      fecha_inicio: startDate,
      fecha_termino: endDate,
      lista_id_unidad: listUnitIds,
      lista_id_puesto: listPositionIds,
      lista_id_sector: listSectorIds,
      lista_id_trabajador: listWorkerIds,
    },
  });

  return response;
}
