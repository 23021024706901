class UnknownInstanceError extends Error {
  constructor(message) {
	super(message);

	this.name = 'UnknownInstanceError';
    this.code = 'ERR_UNKNOWN_INSTANCE';
	this.message = message || 'Unknown instance selected';

	if (Error.captureStackTrace) {
	  Error.captureStackTrace(this, UnknownInstanceError);
	}
  }
}

export default UnknownInstanceError;
