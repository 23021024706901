import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Mixpanel
import mixpanel from 'mixpanel-browser';
// hooks
import useAuth from '@rflex-spa/react-core/dist/base/hooks/useAuth';
// pages
import LoginDT from '@rflex-spa/react-core/dist/base/pages/auth/LoginDT';
import InstanceOfView from '@rflex-spa/react-core/dist/base/pages/work-address/InstanceOfView';
import Home from '@rflex-spa/react-core/dist/base/pages/work-address/Home';
// components
import LoadingScreen from '@rflex-spa/react-core/dist/base/components/LoadingScreen';
import { DT_ROUTES } from '@rflex-spa/react-core/dist/routes/paths';
// config
import appConfig from '../config/app';
// utils
import GoogleTagManager from '../utils/gtm';

// ----------------------------------------------------------------------

DTAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function DTAuthGuard({ children }) {
  const { isAuthenticated, isInitialized, nominus, user } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  mixpanel.init(appConfig.mixpanelToken, { debug: appConfig.mixpanelDebug });

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || (nominus && !nominus.dt)) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginDT />;
  }

  if (nominus && nominus.dt && !nominus.dt.clientSelected) {
    if (pathname === DT_ROUTES.companySelection) {
      return <InstanceOfView />;
    }

    return <Home />;
  }

  if (pathname === DT_ROUTES.home || pathname === DT_ROUTES.companySelection) {
    return <Navigate to={`${DT_ROUTES.root}/report`} />;
  }

  mixpanel.identify(String(user.id));
  mixpanel.people.set({
    $email: user.email,
    $name: user.displayName,
    $avatar: user.avatarUrl,
    'User ID': user.id,
    'Client Name': 'Dirección del Trabajo (DT)',
  });

  GoogleTagManager.setGlobalContext();
  GoogleTagManager.setDTContext(nominus, user);

  if (requestedLocation && (pathname !== requestedLocation)) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
