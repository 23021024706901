import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

const getReasonsCommander = async () => {
  const response = await apiService.get('/motivos/motivosCommander');

  return response;
};

export default getReasonsCommander;
