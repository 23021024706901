import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function updateDetTypeBonusByTypeRotating(iddetBonusType, idtypeRotating) {
  const response = await apiService.post('/bonificacion/detTipoBonificacion/tipoRotativa', {
    iddetTipoBonificacion: iddetBonusType,
    idtipoRotativa: idtypeRotating,
  });

  return response;
}
