import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function validatePreSolicitud(id, value) {
  const response = await apiService.post(`/hedav/preSolicitud/actualizar-validacion/${id}`, {
    validado: value,
  });

  return response;
}
