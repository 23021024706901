import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getPermissionsByEmployee(employeeId, detPermisionIds, startDate, endDate) {
  const response = await apiService.get(`permisos/por-trabajador/${employeeId}`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      listaIdDetPermiso: detPermisionIds,
    },
  });

  return response;
}
