import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getListShiftChanges(data) {
  const response = await apiService.get(`/reporte/cambioTurno`, {
    params: data,
  });
  return response;
}
