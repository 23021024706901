import { Navigate } from 'react-router-dom';
// utils
import { isValidSession, getSession } from '@rflex-spa/react-core/dist/base/utils/jwt';
import { isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';
//
import { ROUTES } from '../routes/paths';

// ----------------------------------------------------------------------

export default function IndexRedirect() {
  const currentSession = getSession();

  let redirectTo = ROUTES.home;

  if (isValidSession(currentSession) && isNotEmpty(currentSession.instanceSelected)) {
    const { userData } = currentSession.instanceSelected;

    if (userData.role === 'worker') {
      redirectTo = ROUTES.teletrabajo;
    }
  }

  return <Navigate to={redirectTo} replace />;
}
