import apiService from '../../utils/getApi';

const getDismissalNotice = async (queryParams) => {
  const response = await apiService.get('cartas/notificacionDespido', {
    params: queryParams,
    responseType: 'blob',
  });

  return response;
};

export default getDismissalNotice;
