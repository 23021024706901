import apiService from '../../utils/getApi';

const getHHEEHNT = async (startDate, endDate, unitIdList, positionIdList) => {
  const response = await apiService.post('/buk/HHEE-HNT', {
    fechaInicio: startDate,
    fechaTermino: endDate,
    listaIdDetPuesto: positionIdList,
    listaIdUnidad: unitIdList,
  });

  return response;
};

export default getHHEEHNT;
