import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of the balance hours by worker
 * @param {string} startDate
 * @param {string} endDate
 * @param {array} workerIdList
 * @returns blob
 */
export default async function getBalanceHoursByWorkerReport(startDate, endDate, workerIdList) {
  const response = await apiService.post(
    `/saldoHora/trabajador/descargar/excel`,
    {
      start_date: startDate,
      end_date: endDate,
      worker_id_list: workerIdList,
    },
    {
      responseType: 'blob',
    }
  );
  return response;
}
