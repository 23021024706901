const config = {
  default: {
    integracionPnet: true,
    acumulaHorasRegistroFuero: '',
    muestraOpcionesValidacion: false,
    muestraAlertaVersionPrueba: false,
    generarInasistenciasTurnoSinMarca: false,
    notificacionAtrasosExtendida: false,
    normalizaHorario4toTurnoFestivo: false,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: false,
      formatoDecimalHorasExtrasEfectivasPartialReporteTurnoExtraSinPeopleNet: false,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: false,
    },
    excepcionesBonificaciones: {
      entregaTurno: [],
      cambioVestuario: [],
    },
    mostrarEntregaTurnoOpcionValidacion: false,
    diasPreliquidacion: 3,
    permitirMenosDescuentoQuePredeterminado: true,
    maximoDeMinutosEntregaDeTurnoValidable: 30,
    maximoDeMinutosEntregaDeTurnoNormal: 45,
    atrasoDescuentaEntregaTurno: true,
    generarCartaDespido: false,
    imprimirNotificacionIncidencia: true,
    mostrarReporteResumidoDatosReversa: false,
    mostrarFichaPeriodoPagoInicioPlanilla: true,
    habilitadaInterfazReversa: false,
    separarExportablesReporteTE: false,
    mostrarAccionesReporteIncidencias: true,
    configReporteEspecialFestivo: {
      reporteBonificacionFestivosConValorHorasBonificadas: false,
      horasTotalBonificadas: 0,
    },
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
    ],
    mostrarBotonNotificarModalAtrasosPorDia: true,
    notificarAtrasosTurnoExtra: true,
    gerenciaReporteBonoPuntualidad: {
      exportarConsolidadoPnet: false,
    },
    sitioFuncionariosReporteTurnosExtraSoloValidados: false,
    unidadesAutorizadasTurnoInvertido: [],
    horaInicioTurnoNocheReporteOcupacionDotacion: '20:00:00',
    serviciosSubserviciosAjusteOnline: [
      {
        estamento: 'Enfermera',
        nombreColumnaEstamento: 'EU/MAT',
        mostrarColumnaEstamento: true,
        mostrarColumnaEstandarCamas: true,
        mostrarColumnaDelta: true,
        combinarResultadosConidDetPuesto: 5,
        colorEstamento: 'bg-primary',
        iddetPuesto: 1,
      },
      {
        estamento: 'Auxiliar de Enfermería',
        nombreColumnaEstamento: 'TENS',
        mostrarColumnaEstandarCamas: true,
        mostrarColumnaEstamento: true,
        mostrarColumnaDelta: true,
        combinarResultadosConidDetPuesto: '',
        colorEstamento: 'bg-new-info-letra-negra',
        iddetPuesto: 2,
      },
      {
        estamento: 'Auxiliar',
        nombreColumnaEstamento: 'ASG',
        mostrarColumnaEstamento: true,
        mostrarColumnaEstandarCamas: false,
        mostrarColumnaDelta: false,
        combinarResultadosConidDetPuesto: '',
        colorEstamento: 'bg-verde-musgo',
        iddetPuesto: 3,
      },
      {
        estamento: 'Matrona',
        nombreColumnaEstamento: 'Matronas',
        mostrarColumnaEstamento: false,
        mostrarColumnaEstandarCamas: false,
        mostrarColumnaDelta: false,
        combinarResultadosConidDetPuesto: '',
        colorEstamento: 'bbg-primary',
        iddetPuesto: 5,
      },
    ],
  },
  stamaria: {
    acumulaHorasRegistroFuero: 'todos',
    muestraOpcionesValidacion: true,
    notificacionAtrasosExtendida: true,
    normalizaHorario4toTurnoFestivo: true,
    sidebarMostrarControlGestion: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    separarExportablesReporteTE: true,
    excepcionesBonificaciones: {
      entregaTurno: [],
      cambioVestuario: [],
    },
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  davila: {
    muestraOpcionesValidacion: true,
    imprimirNotificacionIncidencia: false,
    gerenciaReporteBonoPuntualidad: {
      exportarConsolidadoPnet: true,
    },
    sitioFuncionariosReporteTurnosExtraSoloValidados: true,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 20],
  },
  tabancura: {
    muestraOpcionesValidacion: true,
    generarInasistenciasTurnoSinMarca: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
      formatoDecimalHorasExtrasEfectivasPartialReporteTurnoExtraSinPeopleNet: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  ccdm: {
    acumulaHorasRegistroFuero: 'todos',
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarEntregaTurnoOpcionValidacion: true,
    generarCartaDespido: true,
    imprimirNotificacionIncidencia: false,
    configReporteEspecialFestivo: {
      reporteBonificacionFestivosConValorHorasBonificadas: true,
      horasTotalBonificadas: 6,
    },
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  clc: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    separarExportablesReporteTE: true,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  teamrflex: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  vespucio: {
    muestraOpcionesValidacion: true,
    generarInasistenciasTurnoSinMarca: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  clinicamaitenes: {
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: false,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [],
  },
  cordillera: {
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  loscarrera: {
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  uchospital: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  ucsancarlos: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  ucclinica: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  ucambulatorio: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  hcuch: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  tarapaca: {
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  losleones: {
    muestraOpcionesValidacion: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  biobio: {
    muestraOpcionesValidacion: true,
    generarInasistenciasTurnoSinMarca: true,
    gerenciaReporteTurnosExtra: {
      mostrarOpcionReporteResumido: true,
    },
    gerenciaReporteAtrasosAdelantos: {
      exportarTotalesDecimales: true,
    },
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    maximoDeMinutosEntregaDeTurnoNormal: 0,
    atrasoDescuentaEntregaTurno: false,
    generarCartaDespido: true,
    mostrarAccionesReporteIncidencias: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  casr: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    separarExportablesReporteTE: true,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  hdt: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  hggb: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  ambar: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  uandes: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
    unidadesAutorizadasTurnoInvertido: [5, 6, 13, 52, 55],
  },
  acordillera: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  sanasalud: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [],
  },
  sanatorioaleman: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [],
  },
  cuconcepcion: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [],
  },
  falp: {
    separarExportablesReporteTE: true,
  },
  ist: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
  indisa: {
    notificacionAtrasosExtendida: true,
    mostrarReporteResumidoDatosReversa: true,
    mostrarFichaPeriodoPagoInicioPlanilla: false,
    mostrarBotonNotificarModalAtrasosPorDia: false,
    notificarAtrasosTurnoExtra: false,
    fechasFestivosReporteBonificacionFestivos: [
      {
        festivo: 'AÑO NUEVO',
        fecha: '-12-31',
      },
      {
        festivo: 'DÍA DEL TRABAJADOR',
        fecha: '-04-30',
      },
      {
        festivo: 'INDEPENDENCIA NACIONAL',
        fecha: '-09-18',
      },
      {
        festivo: 'NAVIDAD',
        fecha: '-12-24',
      },
    ],
  },
};

export default config;
