import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Update freschat credentials for "admin" user
 * @param   {string} externalId
 * @param   {string} restoreId
 * @returns {Promise<Object>}
 */
export default async function updateFreshchatCredentials(externalId, restoreId) {
  return apiService.post('/freshchat/update', {
    external_id: externalId,
    restore_id: restoreId,
  });
}
