import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Create freschat credentials for "admin" user
 * @param   {string} externalId
 * @param   {string} restoreId
 * @returns {Promise<Object>}
 */
export default async function addFreshchatCredentials(externalId, restoreId) {
  return apiService.post('/freshchat', {
    external_id: externalId,
    restore_id: restoreId,
  });
}
