import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getFdaDetailByEmployeeId(employeeId, startDate, endDate) {
  const response = await apiService.get(`/fda/trabajador/reporte`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
      idtrabajador: employeeId,
    },
  });

  return response;
}
