import apiService from '../../utils/getApi';

const getReportAclSnapshotsByDateExcel = async (data) => {
  const response = await apiService.post('/acl/snapshots/excel', data, {
    responseType: 'blob',
  });

  return response;
};

export default getReportAclSnapshotsByDateExcel;
