import apiService from '../../utils/getApi';

const linkPosition = async (positionId, detPositionId) => {
  const response = await apiService.post('/auditoria/puesto/enlazar', {
    idPuesto: positionId,
    iddetPuesto: detPositionId,
  });

  return response;
};

export default linkPosition;
