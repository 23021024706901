import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function deleteEnrolledUser(userCode) {
  const response = await apiService.delete('presenza/delete-enrolled-user', {
    params: {
      user_code: userCode,
    },
  });
  return response;
}
