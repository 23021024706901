import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getFdaByEmployeeId(employeeId, startDate, endDate) {
  const response = await apiService.get(`/fda/trabajador/estadoFDA/${employeeId}`, {
    params: {
      fechaInicio: startDate,
      fechaTermino: endDate,
    },
  });

  return response;
}
