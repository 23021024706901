import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getFoodLawByEmployeeId(employeeId) {
  const response = await apiService.get(`/pa/permits`, {
    params: {
      listaIdsTrabajador: [employeeId],
      results_per_page: 0,
      with: 'configuracionesDeTipoTurno',
      with_pagination: 0,
    },
  });

  return response.data;
}
