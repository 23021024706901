import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Function that get an Excel file from API with the data of shift with pendings corrections
 * @param {int} step
 * @param {int|null} result
 * @param {boolean} filterByShiftDate
 * @param {string} startDate
 * @param {string} endDate
 * @param {array} unitsIdList
 * @param {boolean} workerIdList
 * @returns
 */
export default async function getPendingCorrectionReport(
  step,
  result,
  filterByShiftDate,
  startDate,
  endDate,
  unitsIdList,
  workerIdList
) {
  const params = {
    step,
    filter_by_shift_date: filterByShiftDate,
    start_date: startDate,
    end_date: endDate,
  };
  if (result) {
    params.result = result;
  }
  if (unitsIdList) {
    params.units_id_list = unitsIdList;
  }
  if (workerIdList) {
    params.worker_id_list = workerIdList;
  }
  const response = await apiService.post(`/solicitud/corrector-marca/pendientes/descargar/excel`, params, {
    responseType: 'blob',
  });
  return response;
}
