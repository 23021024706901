import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Get the worker's latest active rotary
 * @param   {int} workerId
 * @returns {Promise<Object>}
 */
export default async function getActiveRotary(workerId) {
  return apiService.get(`/rotativa/activa/${workerId}`);
}
