import _ from 'lodash';
import { getSession, isValidSession } from '@rflex-spa/react-core/dist/base/utils/jwt';
import { rol } from '../../constantes/app';

export const getInstanceSelected = () => {
  const currentSession = getSession();

  if (!isValidSession(currentSession)) {
    return null;
  }

  return currentSession.instanceSelected;
};

export const esGerenciaLimitadaPorUnidad = () => {
  const instanceSelected = getInstanceSelected();

  if (instanceSelected == null) {
    return false;
  }

  const {
    userData: { listaRol },
  } = instanceSelected;

  return _.includes(listaRol, rol.gerenciaLimitadaPorUnidad);
};

export const esGerencia = () => {
  const instanceSelected = getInstanceSelected();

  if (instanceSelected == null) {
    return false;
  }

  const {
    userData: { listaRol },
  } = instanceSelected;

  return _.includes(listaRol, rol.gerencia);
}