import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getEmployeeUnlink(queryParams) {
  const response = await apiService.get(`/alta/trabajadorADesvincular`, {
    params: queryParams,
  });
  return response;
}
