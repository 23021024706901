import { useState, useEffect, useCallback } from 'react';
// redux
import { useSelector } from '@rflex-spa/react-core/dist/redux/store';
// utils
import { isEmpty, isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';

import { checkEnabledConfiguration, checkEnabledConfigurationByUnit } from '../utils/config-app';

export default function usePermissions() {
  const { initSettings, userPermissions } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(initSettings.isLoading || userPermissions.isLoading);
  }, [initSettings.isLoading, userPermissions.isLoading]);

  const checkConfig = useCallback((configuracionIngresada, byUnit = false) => {
    if (byUnit) {
      return checkEnabledConfigurationByUnit(initSettings.initSettings, configuracionIngresada);
    }

    return checkEnabledConfiguration(initSettings.initSettings, configuracionIngresada);
  }, [initSettings]);

  const checkPageAccess = useCallback((pageCode) => {
    if (isEmpty(userPermissions.userPermissions)) {
      return false;
    }

    if (userPermissions.userPermissions.rol.code === 'default') {
      return true;
    }

    const routeExists = userPermissions.userPermissions.routes
    .some((route) => route.code === pageCode);

    return routeExists;
  }, [userPermissions]);

  const checkPageAction = useCallback((pageCode, action) => {
    if (isEmpty(userPermissions.userPermissions)) {
      return false;
    }

    if (userPermissions.userPermissions.rol.code === 'default') {
      return true;
    }

    const actionExists = userPermissions.userPermissions.routes
    .filter((route) => route.code === pageCode)
    .some((route) => isNotEmpty(route.actions) && route.actions.includes(action));

    return actionExists;
  }, [userPermissions]);

  return {
    isPermissionsLoading: isLoading,
    userPermissions: userPermissions.userPermissions,
    checkConfig,
    checkPageAccess,
    checkPageAction,
  };
}
