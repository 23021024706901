import apiService from '../../utils/getApi';

const pdfRequestValidation = async (data) => {
  const response = await apiService.post('/validation/document/request', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export default pdfRequestValidation;
