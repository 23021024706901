import { useCallback, useEffect } from 'react';
import useAuth from '@rflex-spa/react-core/dist/base/hooks/useAuth';
import { report } from '@rflex-spa/react-core/dist/sentry';
import { isEmpty, isNotEmpty } from '@rflex-spa/react-core/dist/base/utils/helpers';

import { addFreshchatCredentials, updateFreshchatCredentials } from '../../api';

import fcSettings from '../../config/freshchat';
import { getAppTheme, getAppVersion } from '../../utils/app';

// ----------------------------------------------------

const isDeniedUser = (name) => name.startsWith("admin") || name.startsWith("rrhh");

const isFreshchatSet = () => window.fcWidget !== undefined;

// Inject FreshChat script in the html
// Oficial doc: https://developers.freshchat.com/web-sdk/#intro
const loadScript = () => {
  const elementId = 'freshchat-lib';

  if (document.getElementById(elementId) || isFreshchatSet()) {
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = `${fcSettings.host}/js/widget.js`;
  script.elementId = elementId;
  document.head.appendChild(script);
};

/**
 * @param {Object} settings
 */
const initialize = (settings) => {
  window.fcWidget.init(settings);
};

export default function Freshchat() {
  const { user, instanceSelected, isAuthenticated } = useAuth();

  /**
   * Returns the necessary data to log a user into freshchat
   * @returns object
   */
  const getUserCredentials = useCallback(() => {
    const {
      id: userId,
      loginUsername: firstName,
      role = null,
      email
    } = user;

    const externalId = role === 'worker'
      ? `${instanceSelected.code}_colab_${userId}`
      : `${instanceSelected.code}_admin_${userId}`;

    const credentials = {
      firstName,
      email,
      externalId,
      restoreId: null,
    };

    if (isNotEmpty(user.freshchatCredentials)) {
      credentials.externalId = user.freshchatCredentials.externalId;
      credentials.restoreId = user.freshchatCredentials.restoreId;
    }

    return credentials;
  }, [user, instanceSelected]);

  const setClientProperties = useCallback(() => {
    if (isFreshchatSet()) {
      window.fcWidget.user.setProperties({
        "Platform": "GdP2",
        "AppVersion": getAppVersion(),
        "UserType": user.role === 'worker' ? 'colab' : 'rrhh',
        "Service": getAppTheme(),
      });
    }
  }, [user]);

  /**
   * Update user's freschat credentials
   * @param {Object} data - It must be the Freshchat response data
   * @param {string} data.externalId
   * @param {string} data.restoreId
   */
  const updateCredentials = useCallback(async ({ externalId, restoreId }) => {
    try {
      if (user?.freshchatCredentials?.restoreId) {
        // Update user's Freshchat credentials
        await updateFreshchatCredentials(user.freshchatCredentials.externalId, restoreId);
        return;
      }

      await addFreshchatCredentials(externalId, restoreId);
    } catch (err) {
      report(err);
    }
  }, [user]);

  const setEvents = useCallback(() => {
    if (! isFreshchatSet()) {
      return;
    }

    window.fcWidget.on('user:created', (response) => {
      if (response?.status === 200 && response?.data?.restoreId) {
        updateCredentials(response.data);
      }
    });
  }, [updateCredentials]);

  const destroy = () => {
    if (! isFreshchatSet()) {
      return;
    }

    window.fcWidget.destroy();
  };

  useEffect(() => {
    if (! isAuthenticated) {
      destroy();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const boot = () => {
      if (
        isEmpty(fcSettings.token) ||
        isEmpty(instanceSelected) ||
        isDeniedUser(user.loginUsername)
      ) {
        return;
      }

      const userCredentials = getUserCredentials();

      initialize({ ...fcSettings, ...userCredentials });
      setClientProperties();
      setEvents();
    };

    loadScript();

    // It's necessary to check if Freshchat was initalized before trying to init it
    const interval = setInterval(() => {
      if (isFreshchatSet()) {
        clearInterval(interval);

        try {
          boot();
        } catch (error) {
          console.error(error);
        }
      }
    }, 1000);

    return () => {
      destroy();
    };
  }, [setEvents, setClientProperties, getUserCredentials, user, instanceSelected]);

  return null;
}
