import apiService from '../../utils/getApi';

/**
 * Associate some 'planillas' to the selected 'tipoRotativa'
 * @param   {int} rotatyTypeId
 * @param   {int[]} planillaIdList
 * @returns {Promise<T|null>}
 */
const associatePlanillas = (rotatyTypeId, planillaIdList) => (
  apiService.patch(
    `/tipos-de-rotativa/${rotatyTypeId}/associate/planillas`,
    { lista_id_planilla: planillaIdList }
  )
);

export default associatePlanillas;
