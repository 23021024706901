import appTheme from '../theme';

const appConfig = {
  enableSentry: true,
  loginMethods: ['rflex-api'],
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  mixpanelDebug: process.env.REACT_APP_MIXPANEL_DEBUG,
  appTheme: appTheme[process.env.REACT_APP_THEME || 'rflex'],
};

export default appConfig;
