import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * Generate a report about punctuality
 * @param   {object} dto
 * @param   {string} dto.fechaInicio [Date format: yyyy-MM-dd]
 * @param   {string} dto.fechaTermino [Date format: yyyy-MM-dd]
 * @param   {int[]} dto.listaIdUnidad
 * @param   {int[]} dto.listaIdDetPuesto
 * @param   {boolean} dto.nuevoBono
 * @param   {boolean | null} dto.conMarca
 * @returns {Promise<Blob>}
 */
export default async function generatePunctualityReport(dto) {
  const response = await apiService.post(
    'reporte/puntualidad',
    dto,
    { responseType: 'blob' }
  );

  return response;
}
