import { createSlice } from '@reduxjs/toolkit';
// api
import { getImprovement } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  detImprovements: [],
};

const slice = createSlice({
  name: 'detImprovements',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setDetImprovements(state, action) {
      state.detImprovements = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setDetImprovements } = slice.actions;

// ----------------------------------------------------------------------

export function requestDetImprovements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await getImprovement();
      dispatch(slice.actions.setDetImprovements(data));
    } catch (error) {
      // Error al obtener bonificaciones
      dispatch(slice.actions.hasError(error));
    }
  };
}
