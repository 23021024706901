import axios from 'axios';

// ----------------------------------------------------------------------

/**
 * Obtiene el nps del usuario
 * @param {string} userCode 
 * @returns 
 */
export default async function npsValidation(userCode) {
    const response = await axios.post(`https://us-central1-operations-379520.cloudfunctions.net/nps_validation?user_code=${userCode}`);
    return response.data;
}
