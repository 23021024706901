import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

/**
 * @param   {object} params
 * @param   {string} params.fechaInicio [Date format: 'yyyy-MM-dd']
 * @param   {string} params.fechaTermino [Date format: 'yyyy-MM-dd']
 * @param   {bool} params.porLicencia
 * @param   {int[]} params.listaIdDetTipoBonificacion
 * @param   {int[]?} params.listaIdTrabajador
 * @param   {int[]} params.listaIdUnidad [Required if listaIdTrabajador param is not defined]
 * @param   {int[]} params.listaIdDetPuesto [Required if listaIdUnidad param is defined]
 * @returns {Blob}
 */
export default async function generateWorkerWithBonusesReport(params) {
  const response = await apiService.post(
    'reporte/trabajadores-con-bonificaciones',
    params,
    { responseType: 'blob' }
  );

  return response;
}
