import apiService from '../../../utils/getApi';

// ----------------------------------------------------------------------

export default async function getFoodlawByLeave(data) {
  const response = await apiService.get('/pa/permits', {
    params: data,
  });

  return response.data;
}
