import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function deleteQuitClaim(quitClaimId) {
  const response = await apiService.delete(`/renuncia/${quitClaimId}`, {
    params: {
      objeto: {
        idrenuncia: quitClaimId,
        reload: null,
      },
    },
  });

  return response;
}
