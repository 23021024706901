import apiService from '../../utils/getApi';

// ----------------------------------------------------------------------

export default async function updateBonificationAbsenceType(bonificationTypeDetailId, absenceTypeId) {
  const response = await apiService.post('bonificacion/detTipoBonificacion/tipoAusencia', {
    iddetTipoBonificacion: bonificationTypeDetailId,
    idtipoAusencia: absenceTypeId,
  });
  return response;
}
